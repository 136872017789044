import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import * as firebaseui from "firebaseui"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.GATSBY_API_TOKEN,
  authDomain: "sqs-plugins-247907.firebaseapp.com",
  databaseURL: "https://sqs-plugins-247907.firebaseio.com",
  projectId: "sqs-plugins-247907",
  storageBucket: "sqs-plugins-247907.appspot.com",
  messagingSenderId: "126766467758",
  appId: "1:126766467758:web:6b0b1d139abb9747",
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export { firebaseui }
export default firebase
