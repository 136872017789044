import React, { Fragment } from "react"
import LinearProgress from "@material-ui/core/LinearProgress"
import clsx from "clsx"
import {
  makeStyles,
  useTheme,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListIcon from "@material-ui/icons/List"
import SystemUpdateAlt from "@material-ui/icons/SystemUpdateAlt"
import CameraAlt from "@material-ui/icons/CameraAlt"
import { navigate } from "gatsby"

import "./layout.css"
import "../assets/index.scss"

const overrideTheme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiInput: {
      // Name of the rule
      underline: {
        // Some CSS
        "&:after": {
          borderBottom: "2px solid #f06c87",
        },
      },
    },
  },
})

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#f06c87",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: `${theme.spacing(3)}px 0 0 0`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

const Layout = ({
  isLoading = false,
  style,
  className,
  children,
  withNav = false,
  navTitle = "Tickets",
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const handleMenuNav = route => {
    return () => {
      navigate(`/${route}`)
      setTimeout(() => setOpen(false), 80)
    }
  }

  return (
    <>
      <ThemeProvider theme={overrideTheme}>
        {isLoading && (
          <LinearProgress
            style={{
              position: "fixed",
              top: withNav ? "56px" : 0,
              height: "5px",
            }}
          />
        )}
        <div className={clsx(className, withNav && "nav")} style={style}>
          {withNav ? (
            <>
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" noWrap>
                    {navTitle}
                  </Typography>
                </Toolbar>
              </AppBar>
              <SwipeableDrawer
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </div>
                <Divider />

                <List>
                  {[
                    { text: "All Tickets", route: "list", Icon: ListIcon },
                    {
                      text: "Import Tickets",
                      route: "import",
                      Icon: SystemUpdateAlt,
                    },
                    { text: "Scanner", route: "check-in", Icon: CameraAlt },
                  ].map(({ text, route, Icon }, index) => (
                    <ListItem button key={text} onClick={handleMenuNav(route)}>
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
              </SwipeableDrawer>
            </>
          ) : null}
          <main
            className={clsx({
              [classes.content]: withNav,
              [classes.contentShift]: open && withNav,
            })}
            style={{ width: "inherit" }}
          >
            {children}
          </main>
        </div>
      </ThemeProvider>
    </>
  )
}

export default Layout
